import { useEffect } from 'react';

import { t, translate } from 'react-switch-lang';
import { moveFocusTo } from '../utils/Accessibility';

function SkipToNav() {
  useEffect(() => {
    const elementExists = document.getElementById('skip-to-nav-button');
    if (elementExists) return;
    const divEl = document.createElement('div');
    const buttonEl = document.createElement('button');
    const buttonTextEl = document.createElement('span');
    divEl.id = 'skip-to-nav-div';
    buttonEl.id = 'skip-to-nav-button';
    buttonTextEl.innerText = t('SkipToNavBar_Button');
    divEl.appendChild(buttonEl);
    buttonEl.appendChild(buttonTextEl);
    document.body.appendChild(divEl);
    document.getElementById('skip-to-nav-button').addEventListener('click', () => moveFocusTo('navbar', true));
  }, []);

  return null;
}

export default translate(SkipToNav);
