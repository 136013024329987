import React from 'react';

import { t, translate } from 'react-switch-lang';
import { moveFocusTo } from '../utils/Accessibility';
import styles from '../styles/SkipToContent.module.scss';

function SkipToContent() {
  return (
    <div id={styles.SkipTo}>
      <SkipToButton skipTo="main" scrollTop textKey="SkipToContent_Button" />
      <SkipToButton skipTo="footer" textKey="SkipToFooter_Button" />
    </div>
  );
}

function SkipToButton({ skipTo, textKey, scrollTop = false, ...args }) {
  return <div><button type="button" onClick={() => moveFocusTo(skipTo, scrollTop)} {...args}>{t(textKey)}</button></div>;
}

export default translate(SkipToContent);
