import Amplitude from 'amplitude-js';
import { isDevelopment } from './HostingEnv';

const AMPLITUDE_SESSION_TIMEOUT = 20 * 60 * 1000;
const ampLogStyle = 'background-color:aqua; color:black;';

const logToConsole = isDevelopment ? console.log : () => {}; // eslint-disable-line no-console

const setScreenDimensions = () => {
  if (typeof window !== 'undefined') {
    const identify = new Amplitude.Identify();
    identify.setOnce('ScreenWidth', window.screen.width);
    identify.setOnce('ScreenHeight', window.screen.height);
    Amplitude.getInstance().identify(identify);
  }
};

if (typeof window !== 'undefined') {
  Amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    sessionTimeout: AMPLITUDE_SESSION_TIMEOUT,
  });
  Amplitude.getInstance().setVersionName(process.env.NEXT_PUBLIC_VERSION);

  setScreenDimensions();
}

export const getAmpSessionID = () => Amplitude.getInstance().getSessionId();
export const setAmpUser = (email) => {
  logToConsole(`%c[Amplitude]%c Setting user ID: ${email}`, ampLogStyle, '');
  Amplitude.getInstance().setUserId(email);
};
export const setUserProps = (properties) => {
  logToConsole('%c[Amplitude]%c Setting user property:', ampLogStyle, '', properties);
  Amplitude.getInstance().setUserProperties(properties);
};

export const logAmpEvent = (event, properties = undefined) => {
  logToConsole(
    `Amplitude event: %c${event}`,
    ampLogStyle,
    properties ? 'with properties:' : '',
    properties || ''
  );
  Amplitude.getInstance().logEvent(event, properties);
};

export const setLandingPageUserProps = (pageTitle) => {
  const hasTrackedLandingPage = sessionStorage.getItem('trackedLandingPage');

  if (hasTrackedLandingPage) {
    return;
  }
  const identify = new Amplitude.Identify();
  identify.setOnce('initial_arrival_page', pageTitle);
  identify.set('arrival_page', pageTitle);
  Amplitude.getInstance().identify(identify);

  sessionStorage.setItem('trackedLandingPage', 'true');
};

export const events = {
  // general
  API_SUCCESS: 'API_SUCCESS',
  API_ERROR: 'API_ERROR',
  API_MAINTENANCE_MODE: 'API_MAINTENANCE_MODE',
  USER_VIEWED_ERROR_PAGE: 'USER_VIEWED_ERROR_PAGE',
  USER_CLICKED_ERROR_PAGE_BUTTON: 'USER_CLICKED_ERROR_PAGE_BUTTON',
  USER_VIEWED_UNHANDLED_ERROR_PAGE: 'USER_VIEWED_UNHANDLED_ERROR_PAGE',

  USER_CLICKED_NAVBAR_BRAND: 'USER_CLICKED_NAVBAR_BRAND',
  USER_CLICKED_NAVBAR_HAMBURGER_MENU: 'USER_CLICKED_NAVBAR_HAMBURGER_MENU',
  USER_SEARCHED_BILL_NAVBAR: 'USER_SEARCHED_BILL_NAVBAR',
  USER_CLICKED_NAVBAR_CHECK_PAYMENT_STATUS: 'USER_CLICKED_NAVBAR_CHECK_PAYMENT_STATUS',
  USER_CLICKED_NAVBAR_HELP: 'USER_CLICKED_NAVBAR_HELP',
  USER_CLICKED_NAVBAR_MY_PAYMENT: 'USER_CLICKED_NAVBAR_MY_PAYMENT',

  // Footer
  USER_CHANGED_LANGUAGE: 'USER_CHANGED_LANGUAGE',
  USER_CLICKED_FOOTER_LINK: 'USER_CLICKED_FOOTER_LINK',
  USER_CLICKED_FOOTER_DOWNLOAD_PSM: 'USER_CLICKED_FOOTER_DOWNLOAD_PSM',

  // Search pages
  USER_VIEWED_UNFILTERED_PARTNER_SEARCH_PAGE: 'USER_VIEWED_UNFILTERED_PARTNER_SEARCH_PAGE',
  USER_VIEWED_FILTERED_PARTNER_SEARCH_PAGE: 'USER_VIEWED_FILTERED_PARTNER_SEARCH_PAGE',

  // Payment Process
  USER_SELECTED_ACCOUNT_TYPE_PARTNER: 'USER_SELECTED_ACCOUNT_TYPE_PARTNER',
  USER_OPENED_TRANSACTION_DETAILS_MOBILE: 'USER_OPENED_TRANSACTION_DETAILS_MOBILE',
  USER_CLOSED_TRANSACTION_DETAILS_MOBILE: 'USER_CLOSED_TRANSACTION_DETAILS_MOBILE',
  USER_CLICKED_SHOW_MORE_PARTNERS: 'USER_CLICKED_SHOW_MORE_PARTNERS',

  // Account Details
  USER_VIEWED_ACCOUNT_DETAILS_PAGE: 'USER_VIEWED_ACCOUNT_DETAILS_PAGE',
  USER_CLICKED_ACCOUNT_DETAILS_TERMS: 'USER_CLICKED_ACCOUNT_DETAILS_TERMS',
  USER_CLICKED_DATEPICKER_BUTTON: 'USER_CLICKED_DATEPICKER_BUTTON',
  USER_CLICKED_CONTINUE_BUTTON: 'USER_CLICKED_CONTINUE_BUTTON',
  USER_ENTERED_INVALID_VALUE: 'USER_ENTERED_INVALID_VALUE',
  USER_CLICKED_BACK_BUTTON: 'USER_CLICKED_BACK_BUTTON',
  USER_CLICKED_MAILCHECK_SUGGESTION_YES_BUTTON: 'USER_CLICKED_MAILCHECK_SUGGESTION_YES_BUTTON',
  USER_CLICKED_MAILCHECK_SUGGESTION_NO_BUTTON: 'USER_CLICKED_MAILCHECK_SUGGESTION_NO_BUTTON',
  USER_CLICKED_MAX_AMOUNT_LIMIT_HELP_LINK: 'USER_CLICKED_MAX_AMOUNT_LIMIT_HELP_LINK',

  // Payment Options
  USER_VIEWED_PAYMENT_OPTIONS_PAGE: 'USER_VIEWED_PAYMENT_OPTIONS_PAGE',
  USER_CLICKED_PAYSIMPLY_FEES_HELPCENTRE_LINK: 'USER_CLICKED_PAYSIMPLY_FEES_HELPCENTRE_LINK',
  USER_SELECTED_PAYMENT_OPTION: 'USER_SELECTED_PAYMENT_OPTION',

  // Review Page
  USER_VIEWED_REVIEW_PAGE: 'USER_VIEWED_REVIEW_PAGE',
  USER_CLICKED_REVIEWPAGE_EDIT_ACCOUNT_DETAILS: 'USER_CLICKED_REVIEWPAGE_EDIT_ACCOUNT_DETAILS',
  USER_CLICKED_REVIEWPAGE_EDIT_PAYMENT_OPTION: 'USER_CLICKED_REVIEWPAGE_EDIT_PAYMENT_OPTION',
  USER_CLICKED_CANCEL_BUTTON: 'USER_CLICKED_CANCEL_BUTTON',
  USER_CLOSED_CONFIRMATION_MODAL: 'USER_CLOSED_CONFIRMATION_MODAL',
  USER_CLICKED_CONFIRMATION_MODAL_GO_BACK_BUTTON: 'USER_CLICKED_CONFIRMATION_MODAL_GO_BACK_BUTTON',

  // Main Landing Page
  USER_SEARCHED_BILL_MAIN_LANDING_PAGE: 'USER_SEARCHED_BILL_MAIN_LANDING_PAGE',
  USER_CLICKED_MAIN_LANDING_PAGE_CTA: 'USER_CLICKED_MAIN_LANDING_PAGE_CTA',
  USER_CLICKED_LANDINGPAGE_DOWNLOAD_PSM: 'USER_CLICKED_LANDINGPAGE_DOWNLOAD_PSM',
  USER_CLICKED_MOBILEAPP_LEARN_MORE_LINK: 'USER_CLICKED_MOBILEAPP_LEARN_MORE_LINK',
  USER_CLICKED_PAYMENTSOURCE_LEARN_MORE_LINK: 'USER_CLICKED_PAYMENTSOURCE_LEARN_MORE_LINK',
  USER_CLICKED_TESTIMONIALS_SLIDESHOW_BUTTON: 'USER_CLICKED_TESTIMONIALS_SLIDESHOW_BUTTON',
  USER_CLICKED_TESTIMONIALS_VIEW_MORE_LINK: 'USER_CLICKED_TESTIMONIALS_VIEW_MORE_LINK',

  // Make Payment step
  USER_VIEWED_ETRANSFER_INSTRUCTIONS_PAGE: 'USER_VIEWED_ETRANSFER_INSTRUCTIONS_PAGE',
  USER_OPENED_ETRANSFER_HELPCENTRE_LINK: 'USER_OPENED_ETRANSFER_HELPCENTRE_LINK',
  USER_OPENED_PROCESSING_TIMELINE_HELPCENTRE_LINK: 'USER_OPENED_PROCESSING_TIMELINE_HELPCENTRE_LINK',
  USER_CLICKED_ETRANSFER_CODE_COPY: 'USER_CLICKED_ETRANSFER_CODE_COPY',
  USER_CLICKED_CLOSE_AND_RETURN_HOME_BUTTON: 'USER_CLICKED_CLOSE_AND_RETURN_HOME_BUTTON',
  USER_VIEWED_IN_PERSON_INSTRUCTIONS: 'USER_VIEWED_IN_PERSON_INSTRUCTIONS',
  USER_CLICKED_GET_PAYMENT_CODE_BY_EMAIL_BUTTON: 'USER_CLICKED_GET_PAYMENT_CODE_BY_EMAIL_BUTTON',
  USER_CLICKED_GET_PAYMENT_CODE_BY_TEXT_BUTTON: 'USER_CLICKED_GET_PAYMENT_CODE_BY_TEXT_BUTTON',
  USER_CLICKED_GET_PAYMENT_CODE_BY_PRINT_BUTTON: 'USER_CLICKED_GET_PAYMENT_CODE_BY_PRINT_BUTTON',
  USER_CLICKED_FIND_CP_LOCATIONS: 'USER_CLICKED_FIND_CP_LOCATIONS',
  USER_CLICKED_SEND_TO_EMAIL_BUTTON: 'USER_CLICKED_SEND_TO_EMAIL_BUTTON',
  USER_CLICKED_SEND_TO_PHONE_BUTTON: 'USER_CLICKED_SEND_TO_PHONE_BUTTON',
  USER_CLICKED_MCP_LEARN_MORE_LINK: 'USER_CLICKED_MCP_LEARN_MORE_LINK',
  MONERIS_SDK_LOAD_ERROR: 'MONERIS_SDK_LOAD_ERROR',
  MONERIS_CANCEL_TRANSACTION: 'MONERIS_CANCEL_TRANSACTION',
  MONERIS_PAYMENT_COMPLETE: 'MONERIS_PAYMENT_COMPLETE',
  MONERIS_PAGE_LOADED: 'MONERIS_PAGE_LOADED',
  MONERIS_ERROR_EVENT: 'MONERIS_ERROR_EVENT',
  MONERIS_PAGE_CLOSED: 'MONERIS_PAGE_CLOSED',
  PAYPAL_PAYMENT_ERROR: 'PAYPAL_PAYMENT_ERROR',
  PAYPAL_PAYMENT_CANCELLED: 'PAYPAL_PAYMENT_CANCELLED',
  PAYPAL_PAYMENT_APPROVED: 'PAYPAL_PAYMENT_APPROVED',
  USER_VIEWED_MONERIS_MOBILE_PAGE: 'USER_VIEWED_MONERIS_MOBILE_PAGE',

  // Confirmation step
  USER_VIEWED_CONFIRMATION_PAGE: 'USER_VIEWED_CONFIRMATION_PAGE',
  USER_CLICKED_SEND_RECEIPT_TO_EMAIL_BUTTON: 'USER_CLICKED_SEND_RECEIPT_TO_EMAIL_BUTTON',
  USER_CLICKED_PRINT_RECEIPT_BUTTON: 'USER_CLICKED_PRINT_RECEIPT_BUTTON',

  // Landing Pages
  USER_VIEWED_LANDING_PAGE: 'USER_VIEWED_LANDING_PAGE',

  // CRA Landing Page
  USER_CLICKED_CRA_QUICK_LINK_BUTTON: 'USER_CLICKED_CRA_QUICK_LINK_BUTTON',

  // Error Page
  USER_CLICKED_RETURN_HOME_ERROR_PAGE: 'USER_CLICKED_RETURN_HOME_ERROR_PAGE',
  USER_CLICKED_HELP_CENTRE_LINK_ERROR_PAGE: 'USER_CLICKED_HELP_CENTRE_LINK_ERROR_PAGE',
  USER_VIEWED_404_PAGE: 'USER_VIEWED_404_PAGE',
  USER_VIEWED_500_ERROR_PAGE: 'USER_VIEWED_500_ERROR_PAGE',
  USER_VIEWED_GENERAL_ERROR_PAGE: 'USER_VIEWED_GENERAL_ERROR_PAGE',

  // Maintenance Page
  USER_VIEWED_MAINTENANCE_PAGE: 'USER_VIEWED_MAINTENANCE_PAGE',
  USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON: 'USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON',
  // Google Recaptcha
  GOOGLE_RECAPTCHA_CHALLENGE: 'GOOGLE_RECAPTCHA_CHALLENGE',
  GOOGLE_RECAPTCHA_CANCEL: 'GOOGLE_RECAPTCHA_CANCEL',
  GOOGLE_RECAPTCHA_ERROR: 'GOOGLE_RECAPTCHA_ERROR',
  USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK',
  USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_LINK',

  // My Payment
  USER_VIEWED_MY_PAYMENT_PAGE: 'USER_VIEWED_MY_PAYMENT_PAGE',
  USER_CLICKED_MY_PAYMENT_SEARCH_BUTTON: 'USER_CLICKED_MY_PAYMENT_SEARCH_BUTTON',
  USER_CLICKED_RE_SEND_RECEIPT_TO_EMAIL_BUTTON: 'USER_CLICKED_RE_SEND_RECEIPT_TO_EMAIL_BUTTON',
  USER_CLICKED_SEARCH_ANOTHER_PAYMENT_BUTTON: 'USER_CLICKED_SEARCH_ANOTHER_PAYMENT_BUTTON',

  // Terms and Conditions
  USER_VIEWED_TERMS_AND_CONDITIONS_PAGE: 'USER_VIEWED_TERMS_AND_CONDITIONS_PAGE',

  // Testimonials Page
  USER_VIEWED_TESTIMONIALS_PAGE: 'USER_VIEWED_TESTIMONIALS_PAGE',
};
