import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage, t } from 'react-switch-lang';
import { faBars, faSearch } from '@awesome.me/kit-3f20e419a7/icons/classic/solid';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { faFileInvoice } from '@awesome.me/kit-3f20e419a7/icons/classic/regular';
import styles from '../styles/NavBar.module.scss';
import Link, { ExternalLink } from './Link';
import { events } from '../utils/Amplitude';
import Button from './Button';
import Announcement from './Announcement';

export default function NavBar({ showPaymentInfoButton }) {
  const [showMobileDropDown, setShowMobileDropDown] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [query, setQuery] = useState('');
  const router = useRouter();
  const lang = getLanguage();

  const handleSearch = () => {
    const trimmedQuery = query.trim();
    router.push(`/partner-search/${encodeURIComponent(trimmedQuery)}`);
    setQuery('');
  };

  const toggleMobileMenu = () => {
    setShowMobileDropDown(!showMobileDropDown);
  };
  return (
    <>
      <nav className={`${styles.nav} noPrint`} id="navbar">
        <div className={styles.menuToggle}>
          <Button
            fill="outline"
            className={styles.hamburgerMenu}
            onClick={toggleMobileMenu}
            ampEvent={events.USER_CLICKED_NAVBAR_HAMBURGER_MENU}
            aria-controls="hamburger-dropdown"
            ariaLabel={t('HamBurgerMenu_ButtonDescription')}
            aria-expanded={showMobileDropDown ? 'true' : 'false'}
          >
            <FontAwesomeIcon icon={faBars} size="lg" />
          </Button>
        </div>
        <div className={styles.logoContainer}>
          <Link
            href="/"
            className={styles.logo}
            ampEvent={events.USER_CLICKED_NAVBAR_BRAND}
          >
            <Image
              src="/images/logos/PaySimply-Lg.png"
              alt="PaySimply logo"
              width={100}
              height={29}
            />
          </Link>
        </div>
        {showPaymentInfoButton && (
        <div className={styles.paymentInfoButton}>
          <Button
            fill="outline"
            // the dispatched message event targets the same origin only
            onClick={() => window.postMessage({ type: 'openPaymentInfoModal' })}
            ampEvent={events.USER_OPENED_TRANSACTION_DETAILS_MOBILE}
            ariaLabel={t('PaymentInfo_Open_ButtonDescription')}
          >
            <FontAwesomeIcon icon={faFileInvoice} size="2xl" color="#FFFFFF" />
          </Button>
        </div>
        )}
        <div className={`${styles.navContentContainer} ${showMobileDropDown ? styles.show : ''}`} id="hamburger-dropdown">
          <div className={lang === 'fr' ? styles.wideSearchContainer : styles.searchContainer}>
            <input
              placeholder={t('NavBar_Search_PlaceHolder')}
              type="text"
              inputMode="search"
              className={`${styles.searchInput} form-control`}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            <Button
              linkBtn
              className={styles.searchIcon}
              onClick={handleSearch}
              ampEvent={events.USER_SEARCHED_BILL_NAVBAR}
              ariaLabel={t('SearchButton_IconDescription')}
              title={t('SearchButton_IconDescription')}
            >
              <FontAwesomeIcon
                icon={faSearch}
              />
            </Button>
          </div>
          <Dropdown
            className={styles.dropDown}
            onMouseEnter={() => setShowDropDown(true)}
            onToggle={(nextShow) => setShowDropDown(nextShow)}
            onMouseLeave={() => setShowDropDown(false)}
            show={showDropDown}
          >
            <Dropdown.Toggle
              id="dropbtn"
              className={styles.dropdownDisplay}
            >
              <span>{t('NavBar_Link_MyPayment')}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className={styles.dropDownItems}
            >
              <Dropdown.Item
                as={Link}
                unsetDefaultClass
                href="/my-payment"
                ampEvent={events.USER_CLICKED_NAVBAR_MY_PAYMENT}
                ampEventProps={{ Link: t('NavBar_DropDown_PayMentStatus', null, 'en') }}
              >
                {t('NavBar_DropDown_PayMentStatus')}
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                unsetDefaultClass
                href="/my-payment"
                ampEvent={events.USER_CLICKED_NAVBAR_MY_PAYMENT}
                ampEventProps={{ Link: t('NavBar_DropDown_Receipt', null, 'en') }}
              >
                {t('NavBar_DropDown_Receipt')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ExternalLink
            href={t('Link_PaySimplyHelp')}
            text={t('NavBar_Link_HelpCentre')}
            className={styles.navLink}
            ampEvent={events.USER_CLICKED_NAVBAR_HELP}
            aria-label={t('PaySimplyHelpCentre_LinkDescription')}
          />
        </div>
      </nav>
      <Announcement />
    </>
  );
}
