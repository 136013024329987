import Head from 'next/head';
import { t } from 'react-switch-lang';
import React, { useEffect } from 'react';
import { setPageTitle } from '../utils/Accessibility';
import { setLandingPageUserProps } from '../utils/Amplitude';
import NavBar from './NavBar';
import Footer from './Footer';
import styles from '../styles/MainLayout.module.scss';
import SkipToContent from './SkipToContent';
import SkipToNav from './SkipToNav';

/**
 * Layout wrapper for all pages.
 *
 * Renders NavBar and Footer, alongside any children.
 *
 * Handles accessibility such as page titles and renders skip to links.
 *
 * @param {object} props
 * @param {string} props.title accessible page title passed into `<Head>`
 * @param {string} [props.description] description meta tag passed into `<Head>`
 * @param {string} [props.ogTitle] og:title meta tag passed into `<Head>`.
 * Default value is the title prop.
 * @param {string} [props.ogDescription] og:description meta tag passed into `<Head>`.
 * Default value is the description prop.
 * @param {string} [props.ogImage] URL of the image passed as og:image meta tag into `<Head>`.
 * @param {string} [props.ogUrl] URL of the page passed as og:url meta tag into `<Head>`.
 * @param {boolean} [props.showPaymentInfoButton]
 *  indicates if the mobile navbar should show the button to open the payment info modal
 * @param {React.ReactNode} props.children ReactNode to be rendered inside wrapper
 * @param {string} [props.className] appended after the default class, "content"
 * @param {boolean} [props.hideNavAndFooter]
 */
export default function MainLayout({
  title,
  description,
  ogTitle = title,
  ogDescription = description,
  ogImage,
  ogUrl,
  showPaymentInfoButton = false,
  children,
  className = '',
  hideNavAndFooter = false,
}) {
  // Page title for accessibility
  if (title) setPageTitle(title);
  let pageTitle;
  if (!title) {
    pageTitle = t('OfficialSiteName');
  } else {
    pageTitle = `${title} | ${t('OfficialSiteName')}`;
  }

  useEffect(() => {
    setLandingPageUserProps(pageTitle);
  }, [pageTitle]);

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        {description && <meta name="description" content={description} />}
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
        {ogUrl && <meta property="og:url" content={ogUrl} />}
        <meta property="og:type" content="website" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div id="main-cont" className={styles.mainLayout}>
        <div
          tabIndex={-1}
          id="focus-helper"
          className="visuallyHidden"
        />
        <SkipToContent />
        { hideNavAndFooter ||
          <NavBar showPaymentInfoButton={showPaymentInfoButton} /> }
        <div id="main" className={`content ${className}`}>
          {children}
        </div>
        { hideNavAndFooter ||
          <Footer /> }
        <SkipToNav />
      </div>
    </>
  );
}
