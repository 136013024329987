/* eslint-disable react/no-danger */
import { Fragment } from 'react';
import { getLanguage } from 'react-switch-lang';

/**
 * Wraps the '®' character with the <sup> tag and
 * replaces '™' with MC wrapped in <sup> tag in french.
 * @param {object} props
 * @param {string} props.text Raw text to be injected with <sup> tags.
 * @returns {React.ReactNode} span with superscript characters wrapped in <sup> tag.
 */
export function ReplaceSuperScript({ text }) {
  const lang = getLanguage();
  let superScripted = text.replaceAll('®', '<sup>®</sup>');
  if (lang === 'fr') {
    superScripted = superScripted.replaceAll('™', '<sup>MC</sup>');
  }
  return <span dangerouslySetInnerHTML={{ __html: superScripted }} />;
}

/**
 * Replaces instances of replaceKey with replaceWith in text.
 * @param {object} props
 * @param {string} props.text text to be converted
 * @param {string} props.replaceKey pattern to be replaced
 * @param {React.ReactNode} props.replaceWith ReactNode to replace the replaceKey with
 * @param {string[]} [props.exceptions]
 *   prevents replacement if replaceKey is followed by one of these
 * @returns {string | React.ReactNode[]} original text if not found, else array of ReactNodes
 */
export default function ReplaceTextWithElement({
  text,
  replaceKey,
  replaceWith,
  exceptions,
}) {
  if (!text.includes(replaceKey)) return text;

  return text.split(replaceKey).map((e, i) => {
    if (i === 0) return <ReplaceSuperScript text={e} key={e} />;
    return (
      <Fragment key={e}>
        {exceptions?.some((exception) => e.startsWith(exception)) ? replaceKey : replaceWith}
        <ReplaceSuperScript text={e} />
      </Fragment>
    );
  });
}
