import { t } from 'react-switch-lang';
import { captureException, captureMessage } from '@sentry/nextjs';
import { isDevelopment, isProduction } from './HostingEnv';
import { events, logAmpEvent } from './Amplitude';

const log = isDevelopment ? (path, data) => {
  // eslint-disable-next-line no-console
  console.log(`API: %c${path}`, 'background-color: greenyellow;color:black;', data);
} : () => {};

async function makeCall(path, auth, data) {
  const reqBody = { ...data, Platform: 'Web' };
  const url = `${process.env.NEXT_PUBLIC_API_URL}${path}`;
  let APICALL_SEQ;

  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
      Authorization: auth,
    },
    body: JSON.stringify(reqBody),
  }).then(async (res) => {
    const respBody = await res.json();
    APICALL_SEQ = res.headers.get('APICALL_SEQ');
    log(path, { url, reqBody, respBody, respStatus: res.status, APICALL_SEQ });

    if (res.status !== 200) {
      const err = new Error(`${JSON.stringify(respBody)}`);
      err.name = res.status;
      throw err;
    }

    return respBody;
  }).catch((err) => {
    if (err && isProduction) {
      if (err instanceof Error) captureException(err);
      else captureMessage(JSON.stringify(err));
    }
    return {
      Result: -1, // mock result code for general fetch failures (network failures, etc)
      Description: t('Error_Default'),
    };
  });

  if (resp.Result === 0) {
    logAmpEvent(events.API_SUCCESS, { Call: path });
  } else if (resp.Result === 80) {
    logAmpEvent(events.API_MAINTENANCE_MODE, {
      Call: path,
      'Result Code': resp.Result,
    });
  } else {
    const ampProps = {
      Call: path,
      'Result Code': resp.Result,
      Description: resp.Description,
    };
    if (APICALL_SEQ) ampProps.APICALL_SEQ = APICALL_SEQ;
    logAmpEvent(events.API_ERROR, ampProps);
  }

  return resp;
}

/**
 * If the API call errored, handles the error and returns true.
 * If the API call was successful, returns false.
 * @param {object} res API response body
 * @param {(errMsg: string) => {}} errMsgCallback
 * @param {import('next/router').NextRouter} router for routing to maintenance page
 * @param {function} cleanup cleanup function to be executed before redirecting to maintenance page
 * @returns {boolean} whether the API response was handled (i.e. the API call failed)
 */
export function apiErrorHandled(res, errMsgCallback, router = undefined, cleanup = undefined) {
  if (res.Result === 0) return false; // not handled
  if (res.Result === 80 && router) {
    cleanup?.();
    router.push('/maintenance');
    return true;
  }
  errMsgCallback?.(res.Description || res.UserDisplay || t('Error_Default'));
  return true;
}

export async function beginPayment(auth, {
  PartnerSeq,
  PaymentOption,
  Fee,
  Fields,
  Language,
  PostalCode = undefined,
  ReturnUrl = undefined,
  Referral = undefined,
  SiteMode = undefined,
}) {
  const reqBody = {
    PartnerSeq,
    PaymentOption,
    Fee,
    Fields,
    Language,
  };
  if (PostalCode) reqBody.PostalCode = PostalCode;
  if (ReturnUrl) reqBody.ReturnUrl = ReturnUrl;
  if (Referral) reqBody.Referral = Referral;
  if (SiteMode) reqBody.SiteMode = SiteMode;
  return makeCall('/v1/BeginPayment', auth, reqBody);
}

export async function paymentCallback(auth, {
  PaymentOption,
  PaymentCode,
  Payload,
  Email,
  Language,
}) {
  const reqBody = {
    PaymentOption,
    PaymentCode,
    Payload,
    Email,
    Language,
  };
  return makeCall('/v1/PaymentCallback', auth, reqBody);
}

export async function completePayment(auth, {
  Email,
  PaymentOption,
  PaymentCode,
  PayerId = undefined,
  PayPalSeq = undefined,
  Language,
}) {
  const reqBody = {
    Email,
    PaymentOption,
    PaymentCode,
    Language,
  };
  if (PayerId) reqBody.PayerId = PayerId;
  if (PayPalSeq) reqBody.PayPalSeq = PayPalSeq;
  return makeCall('/v1/CompletePayment', auth, reqBody);
}

export async function sendInPersonInstructions(auth, {
  Email,
  Delivery,
  Language,
}) {
  return makeCall('/v1/SendInPersonInstructions', auth, {
    Email,
    Delivery,
    Language,
  });
}

export async function checkMaintenanceMode() {
  // This call does not require auth or any request body
  return makeCall('/v1/MaintenanceMode', '', {});
}

export async function lookUpPayment(auth, {
  TransNo,
  PaymentDate,
  ClientRef,
  PaymentAmount,
  Language,
}) {
  const reqBody = {
    Language,
  };

  if (TransNo) {
    reqBody.TransNo = TransNo;
  } else {
    reqBody.PaymentDate = PaymentDate;
    reqBody.ClientRef = ClientRef;
    reqBody.PaymentAmount = PaymentAmount;
  }
  return makeCall('/v1/LookUpPayment', auth, reqBody);
}

export async function resendReceipt(auth, {
  Email,
  TransNo,
  Language,
}) {
  return makeCall('/v1/ResendReceipt', auth, {
    Email,
    TransNo,
    Language,
  });
}

export async function getCustomerAnnouncement() {
  // This call does not require auth or any request body
  return makeCall('/v1/GetCustomerAnnouncement', '', {});
}
