import { getLanguage, t } from 'react-switch-lang';
import { faCity, faGraduationCap, faSearch, faUser, faUsers, faGlobeAmericas, faChevronDown } from '@awesome.me/kit-3f20e419a7/icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlberta, faQuebec } from '@awesome.me/kit-3f20e419a7/icons/kit/custom';
import { Col, Dropdown, Row } from 'react-bootstrap';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styles from '../styles/Footer.module.scss';
import Link, { ExternalLink } from './Link';
import ReplaceTextWithElement, { ReplaceSuperScript } from './ReplaceTextWithElement';
import { events, logAmpEvent } from '../utils/Amplitude';
import { isProduction } from '../utils/HostingEnv';
import { getPayLinkPrefix } from '../utils/Helpers';

function FooterLink({ href, icon, text, ampEvent, ampEventProps }) {
  return (
    <div className={styles.footerLink}>
      <Link href={href} ampEvent={ampEvent} ampEventProps={ampEventProps}>
        <FontAwesomeIcon
          icon={icon}
          size="1x"
        />
        {text}
      </Link>
    </div>
  );
}

function languageToFull() {
  return getLanguage() === 'fr' ? 'Français' : 'English';
}

function widgetLanguage(lang) {
  const iframe = document.getElementById('iframe-widget');
  if (!iframe?.src) return;
  iframe.src = iframe.src.slice(0, -2) + lang;

  const globalVarFromHelpCentreScript = WidgetText; /* eslint-disable-line no-undef */
  if (!globalVarFromHelpCentreScript) return;
  document.getElementById('widget-button')?.setAttribute('aria-label', globalVarFromHelpCentreScript[lang]);
  document.getElementById('widget-button-tooltip')?.replaceChildren(globalVarFromHelpCentreScript[lang]);
}

function LangBtn({ lang, children, ...props }) {
  const router = useRouter();
  let { pathname, asPath } = router;

  return (
    <Dropdown.Item
      {...props}
      onClick={async () => {
        if (router.locale === lang) return;
        logAmpEvent(events.USER_CHANGED_LANGUAGE, { Language: lang === 'fr' ? 'French' : 'English' });
        widgetLanguage(lang);

        // switch between /pay/ and /payer/ when changing language
        const wrongPathSegment = `/${getPayLinkPrefix(router.locale)}/`;
        if (pathname.includes(`${wrongPathSegment}[seqOrVanity]`)) {
          const correctPathSegment = `/${getPayLinkPrefix(lang)}/`;
          pathname = pathname.replace(wrongPathSegment, correctPathSegment);
          asPath = asPath.replace(wrongPathSegment, correctPathSegment);
        }

        // https://nextjs.org/docs/14/pages/building-your-application/routing/internationalization#transition-between-locales
        router.push({ pathname, query: router.query }, asPath, { locale: lang });
      }}
      aria-label={t('Footer_LanguageSwitch', null, lang)}
      lang={lang}
    >
      {children}
    </Dropdown.Item>
  );
}

function LanguageDropdown() {
  return (
    <Dropdown className={styles.dropdownLanguage}>
      <Dropdown.Toggle id="dropbtn">
        <div className={styles.dropdownDisplay}>
          <FontAwesomeIcon icon={faGlobeAmericas} />
          <span className="curLang">{languageToFull()}</span>
          <FontAwesomeIcon icon={faChevronDown} size="xs" className={styles.dropDownIcon} />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className={styles.dropDownMenu}>
        <LangBtn lang="en">English</LangBtn>
        <LangBtn lang="fr" style={{ marginTop: '5px' }}>
          Français
        </LangBtn>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default function Footer() {
  return (
    <footer className={`${styles.footer} noPrint`} id="footer">
      <div className={styles.footerContainer}>
        <Row className={styles.footerDescriptionContainer}>
          <Col md={9} sm={12} className={`col-md-9 col-sm-12 ${styles.footerDescription}`}>
            <p>
              <ReplaceTextWithElement
                text={t('Footer_PaySimply_Description')}
                replaceWith={<i>Interac</i>}
                replaceKey="Interac"
              />
            </p>
          </Col>
          <Col md={3} sm={12}>
            <LanguageDropdown />
          </Col>
        </Row>
        <Row className={`row ${styles.footerLinkContainer}`}>
          <Col md={3} sm={12} className={styles.linkGroup}>
            <h3 className={styles.linkGroupTitle}>{t('Footer_PayBills_Title')}</h3>
            <FooterLink
              href="/partner-search"
              icon={faCity}
              text={t('Footer_PayBills_Link1')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: t('Footer_PayBills_Link1', null, 'en') }}
            />
            <FooterLink
              href="/partner-search"
              icon={faGraduationCap}
              text={t('Footer_PayBills_Link2')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: t('Footer_PayBills_Link2', null, 'en') }}
            />
            <FooterLink
              href="/partner-search"
              icon={faSearch}
              text={t('Footer_PayBills_Link3')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: t('Footer_PayBills_Link3', null, 'en') }}
            />
          </Col>
          <Col md={3} sm={12} className={styles.linkGroup}>
            <h3 className={styles.linkGroupTitle}>{t('Footer_PayCRA_Title')}</h3>
            <FooterLink
              href="/lp/cra-individual"
              icon={faUser}
              text={t('Footer_PayCRA_Link1')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: t('Footer_PayCRA_Link1', null, 'en') }}
            />
            <FooterLink
              href="/lp/cra-business"
              icon={faUsers}
              text={t('Footer_PayCRA_Link2')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: t('Footer_PayCRA_Link2', null, 'en') }}
            />
          </Col>
          <Col md={3} sm={12} className={styles.linkGroup}>
            <h3 className={styles.linkGroupTitle}>{t('Footer_PayProvince_Title')}</h3>
            <FooterLink
              href="/lp/albertatra"
              icon={faAlberta}
              text={t('Footer_PayProvince_Link1')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: t('Footer_PayProvince_Link1', null, 'en') }}
            />
            <FooterLink
              href={isProduction ? `/${getPayLinkPrefix()}/48551` : `/${getPayLinkPrefix()}/43420`}
              icon={faQuebec}
              text={t('Footer_PayProvince_Link2')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: t('Footer_PayProvince_Link2', null, 'en') }}
            />
          </Col>
          <Col md={3} sm={12} className={`${styles.mobileApp}`}>
            <h3 className={styles.linkGroupTitle}>{t('Footer_MobileApp_Title')}</h3>
            <p>
              {t('Footer_MobileApp_Blurb')}
              {' '}
            </p>
            <Link
              href="/mobile"
              aria-label={t('Footer_MobileAppLearnMore_LinkDescription')}
              ampEvent={events.USER_CLICKED_FOOTER_LINK}
              ampEventProps={{ Link: 'Mobile App - Learn more' }}
            >
              {t('Footer_MobileApp_CTA')}
            </Link>
            <div className={styles.appLinksContainer}>
              <ExternalLink
                href={t('Link_PaySimplyMobileiOSApp')}
                aria-label={t('PaySimplyMobileApp_AppStore_LinkDescription')}
                ampEvent={events.USER_CLICKED_FOOTER_DOWNLOAD_PSM}
                ampEventProps={{ Store: 'App Store' }}
              >
                <Image
                  src="/images/logos/AppStore.png"
                  height={40}
                  width={140}
                  alt={t('PaySimplyMobileApp_AppStore_ImageDescription')}
                />
              </ExternalLink>
              <ExternalLink
                href={t('Link_PaySimplyMobileAndroidApp')}
                aria-label={t('PaySimplyMobileApp_PlayStore_LinkDescription')}
                ampEvent={events.USER_CLICKED_FOOTER_DOWNLOAD_PSM}
                ampEventProps={{ Store: 'Google Play' }}
              >
                <Image
                  src="/images/logos/PlayStore.png"
                  height={40}
                  width={140}
                  alt={t('PaySimplyMobileApp_PlayStore_ImageDescription')}
                />
              </ExternalLink>
            </div>
          </Col>
        </Row>
        <Row className={styles.termsContainer}>
          <Col md={6} sm={12}>
            <p className={styles.boldLink}>
              <ReplaceSuperScript text={t('Footer_Disclaimer')} />
              {' '}
              <ExternalLink
                href="https://paymentsource.ca/"
                aria-label={t('Footer_PaymentSource_LinkDescription')}
                ampEvent={events.USER_CLICKED_FOOTER_LINK}
                ampEventProps={{ Link: t('Footer_Disclaimer_PaymentSource_Link', null, 'en') }}
              >
                {t('Footer_Disclaimer_PaymentSource_Link')}
              </ExternalLink>
            </p>
            <div>
              <Link
                href="/terms"
                ampEvent={events.USER_CLICKED_FOOTER_LINK}
                ampEventProps={{ Link: t('Footer_PaySimply_TermsAndCondition', null, 'en') }}
              >
                {t('Footer_PaySimply_TermsAndCondition')}
              </Link>
              •
              <ExternalLink
                href={t('Link_PaySimplyPrivacy')}
                aria-label={t('Footer_PrivacyPolicy_LinkDescription')}
                ampEvent={events.USER_CLICKED_FOOTER_LINK}
                ampEventProps={{ Link: t('Footer_PaySimply_PrivacyPolicy', null, 'en') }}
              >
                {t('Footer_PaySimply_PrivacyPolicy')}
              </ExternalLink>
              •
              <ExternalLink
                href={t('Link_AccessibilityStatement')}
                aria-label={t('Footer_Accessibility_LinkDescription')}
                ampEvent={events.USER_CLICKED_FOOTER_LINK}
                ampEventProps={{ Link: t('Footer_AccessibilityStatement', null, 'en') }}
              >
                {t('Footer_AccessibilityStatement')}
              </ExternalLink>
              •
              <ExternalLink
                href={t('Link_PaySimplyContactUsPage')}
                aria-label={t('Footer_ContactUs_LinkDescription')}
                ampEvent={events.USER_CLICKED_FOOTER_LINK}
                ampEventProps={{ Link: t('Footer_PaySimply_ContactUs', null, 'en') }}
              >
                {t('Footer_PaySimply_ContactUs')}
              </ExternalLink>
            </div>
          </Col>

          <Col md={4} sm={12} className={styles.addressContainer}>
            <p className={styles.highlightedText}>{t('MainLanding_OwnershipHighlight')}</p>
            <p>{t('Footer_OfficeLocations')}</p>
            <p>{t('Footer_OfficeAddress')}</p>
          </Col>
        </Row>
      </div>
      <div className={styles.footerThirdPartyDisclaimers}>
        <div className={styles.footerDisclaimerContainer}>
          <p>
            <ReplaceTextWithElement
              text={t('Footer_Disclaimer_Interac')}
              replaceWith={<i>Interac</i>}
              replaceKey="Interac"
            />
          </p>
          <p>{t('Footer_Disclaimer_CanadaPost')}</p>
          <p>{t('Footer_Disclaimer_AppStores')}</p>
        </div>
      </div>
    </footer>
  );
}
