import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getLanguage, t } from 'react-switch-lang';
import mailcheck from 'mailcheck';
import { setScreenReaderAlert } from './Accessibility';
import { events, logAmpEvent } from './Amplitude';

// Helper to get the correct language string based on the current language.
// If the language string is empty for the current language,
// it will fallback to the string for the other language.
export function getLanguageString(en = '', fr = '') {
  const lang = getLanguage();
  return lang === 'fr' ? (fr || en) : (en || fr);
}

// Helper to get the correct link prefix based on the current language.
// If the language is 'fr', it will return '/payer/'.
// Otherwise, it will return '/pay/'.
export function getPayLinkPrefix(lang = undefined) {
  if (!lang) lang = getLanguage(); // eslint-disable-line no-param-reassign
  return lang === 'fr' ? 'payer' : 'pay';
}

/**
 * @param {
    import('../context/PaymentProcessContext').EnFr |
    import('../context/PaymentProcessContext').EnFrOrKey
   } langObj
 * @returns {string} the text in the current language
 */
export function getText(langObj) {
  if (!langObj) return '';
  let en = langObj.en || langObj.En;
  let fr = langObj.fr || langObj.Fr;
  const key = langObj.Key;

  // Try using dedicated en/fr values first
  if (!en) en = fr; // If en text is empty, default to fr text
  if (!fr) fr = en; // If fr text is empty, default to en text

  // If dedicated en/fr values are empty, but key is present,
  // return text from the lang file using the key
  if (!en && !fr && key) {
    const text = t(key);
    // return empty string when either: key is not found or value is empty string
    // this is mostly for AccountNumField_HelpText, which is intentionally empty
    return key === text ? '' : text;
  }

  // otherwise return the text in the current language
  return getLanguage() === 'fr' ? fr : en;
}

/**
 * Pass in the form values object to this function before validating/submitting them
 * to normalize leading/trailing spaces and iOS single quotes.
 * @returns {void} undefined; the parameter object will be modified in place
 */
export function normalizeFormValues(values) {
  Object.keys(values).forEach((key) => {
    if (typeof values[key] === 'string') {
      // eslint-disable-next-line no-param-reassign
      values[key] = values[key].trim().replaceAll(/[‘’]/g, "'");
    }
  });
}

export function invalidFormAlert(invalid, errors, singleError = false) {
  let alertString = '';
  // ignore error key "_"; this is used in
  // first-time submit errors (warnings) where users can proceed by resubmitting
  const errorFields = Object.keys(errors).filter((name) => name !== '_');
  if (invalid && errors && errorFields.length > 0) {
    if (singleError) {
      setScreenReaderAlert(Object.values(errors)[0]);
      return;
    }
    // focus on first invalid element
    const focusElem = document.getElementsByName(errorFields[0])[0];
    focusElem?.focus();
    focusElem?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // loop though all validation errors
    errorFields.forEach((item) => {
      if (!singleError && errors[item] !== true) {
        const e = document.getElementById(`${item}Lbl`)?.firstChild?.textContent;
        if (e) {
          alertString += `${e}, `;
        }
      }
      const itemFormatted = item.replace(/[0-9]/g, '').replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
      logAmpEvent(events.USER_ENTERED_INVALID_VALUE, {
        Field: (itemFormatted.charAt(0).toUpperCase() + itemFormatted.slice(1)).replace(/\s+/g, ' '),
        Reason: errors[item] !== true ? errors[item] : 'Required',
      });
    });
    if (alertString.length > 0) {
      setScreenReaderAlert(t('ScreenReaderAlert_InvalidFormField').replace('xField', alertString));
    }
  }
}

/**
 * Prompts the user with a confirmation dialog when attempting to leave the page. If the calling
 * component needs to set router.beforePopState, pass in the handler as an argument to this hook.
 * @param {function} [beforePopState] popstate event handler, as this hook sets the
 * beforePopState callback and Next.js router supports having only one callback
 * @returns {() => void} function to disable the confirm prompt and unsubscribe the event listeners
 */
export function useFormExitConfirm(beforePopState = undefined) {
  const [unsubscribed, setUnsubscribed] = useState(false);
  const router = useRouter();
  const confirmationMsg = t('PaymentProcess_FormExit_Confirmation');

  const handleRouteChange = useCallback((url) => {
    if (unsubscribed) return;
    // changing language, allow route change
    if (url.endsWith(router.asPath)) return;
    // user confirmed, allow route change
    if (window.confirm(confirmationMsg)) return; // eslint-disable-line no-alert

    // otherwise prevent route change

    // for popstate (browser back/forward), restore the previous state (go to oldPosition)
    if (window.isPopState) {
      const newPosition = window.historyKeys.indexOf(window.history.state.key);
      const oldPosition = window.historyKeys.indexOf(window.lastHistoryKey);
      window.undoingPopState = true;
      if (newPosition < oldPosition) window.history.forward();
      if (newPosition > oldPosition) window.history.back();
    }
    // have to throw literal to prevent nextjs from complaining about uncaught runtime exception
    throw 'User aborted redirect.'; // eslint-disable-line no-throw-literal
  }, [confirmationMsg, router.asPath, unsubscribed]);

  const handleUnload = useCallback((e) => {
    if (unsubscribed) return;
    e.preventDefault();
    e.returnValue = confirmationMsg;
  }, [confirmationMsg, unsubscribed]);

  const removeListeners = useCallback(() => {
    router.events.off('routeChangeStart', handleRouteChange);
    window.removeEventListener('beforeunload', handleUnload);
    router.beforePopState();
  }, [handleRouteChange, handleUnload, router]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    window.addEventListener('beforeunload', handleUnload);
    router.beforePopState((state) => {
      if (!window.undoingPopState) window.isPopState = true;
      window.undoingPopState = false;
      return beforePopState?.(state) ?? true;
    });
    return removeListeners;
  }, [beforePopState, handleRouteChange, handleUnload, router, removeListeners]);

  return () => {
    setUnsubscribed(true);
    removeListeners();
  };
}

/**
 * Returns a promise that resolves with the reCAPTCHA token, or rejects when either the reCAPTCHA
 * challenge is prompted, or aborted by the user. (depending on the `rejectWhen` parameter)
 * Make sure to call ref.current.reset() after the token is consumed by the API. Once reCAPTCHA
 * is executed, this function will always resolve with the same token until reCAPTCHA is reset.
 *
 * @param {*} reCaptchaRefValue the `.current` value of the ref passed into the ReCAPTCHA component
 * @param {'CANCEL'|'CHALLENGE'} rejectWhen determeines when the promise should reject
 *  - `CANCEL` promise rejects when user closes the challenge window
 *  - `CHALLENGE` promise rejects when the challenge window opens
 * @param {() => *} [onAbort] callback to execute when user aborts reCAPTCHA challenge
 * @returns {Promise<string>} a promise that resolves to the reCAPTCHA token, or rejects
 */
export async function executeReCaptcha(reCaptchaRefValue, rejectWhen, onAbort) {
  return new Promise((resolve, reject) => {
    // find the reCAPTCHA challenge window
    const list = document.querySelectorAll("iframe[src*='google.com/recaptcha/api2/bframe']");
    if (list.length > 0) {
      const recaptchaWindow = list[list.length - 1].parentNode.parentNode;

      new MutationObserver(([{ target }], observer) => {
        if (target.style.opacity === '1') { // challenge window opened
          logAmpEvent(events.GOOGLE_RECAPTCHA_CHALLENGE);
          if (rejectWhen === 'CHALLENGE') reject();
        }
        if (target.style.opacity === '0') { // challenge window closed by clicking away
          logAmpEvent(events.GOOGLE_RECAPTCHA_CANCEL);
          onAbort?.();
          observer.disconnect();
          if (rejectWhen === 'CANCEL') reject();
        }
      }).observe(recaptchaWindow, { attributeFilter: ['style'] });
    }

    const reCaptchaToken = reCaptchaRefValue.getValue();

    if (reCaptchaToken) {
      resolve(reCaptchaToken);
    } else {
      reCaptchaRefValue.executeAsync().then(resolve);
    }
  });
}

/**
 * Validates an email address using the mailcheck library.
 * @param {string} value email address to validate
 * @returns {string|null} suggested email address, or null if no suggestion
 */
export const mailcheckValidate = (value) => mailcheck.run({
  email: value,
  suggested(suggestion) {
    return suggestion.full;
  },
  empty() {
    return null;
  },
});

export const formatAmountEn = (value) => {
  if (!value) return '';
  if (/^\.$/.test(value)) return '0.';
  return value.replace(/[^0-9,.]/g, '');
};

export const formatAmountFr = (value) => {
  if (!value) return '';
  if (/^,$/.test(value)) return '0,';
  return value.replace(/[^0-9,.\s]/g, '');
};

export function formatPaymentAmount(amount, omitDecimal) {
  const lang = getLanguage();
  const formatter = new Intl.NumberFormat(`${lang}-CA`, {
    minimumFractionDigits: omitDecimal ? 0 : 2,
    maximumFractionDigits: omitDecimal ? 0 : 2,
  });
  return formatter.format(amount);
}

export const parseAmountStr = (amount) => {
  const trimmed = `${amount || 0}`.replace(/\s/g, '');
  let normalizedAmountStr = '';
  if (trimmed.length - trimmed.lastIndexOf(',') <= 3) {
    // This means the last comma is a decimal separator
    // Replace the last comma with a period and remove all other commas
    normalizedAmountStr = trimmed.replace(/,([^,]*)$/, '.$1').replaceAll(',', '');
  } else {
    // This means the last comma is not a decimal separator
    normalizedAmountStr = trimmed.replaceAll(',', '');
  }
  return parseFloat(normalizedAmountStr || 0).toString();
};

export function localStorageSet(key, value) {
  try {
    if (typeof localStorage === 'undefined') return false;
    localStorage.setItem(key, value);
    return true;
  } catch (err) {
    return false;
  }
}

export function localStorageGet(key) {
  try {
    if (typeof localStorage === 'undefined') return false;
    return localStorage.getItem(key);
  } catch (err) {
    return false;
  }
}
