/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage, t } from 'react-switch-lang';
import ReactMarkdown from 'react-markdown';
import { faTimes } from '@awesome.me/kit-3f20e419a7/icons/classic/solid';

import { localStorageGet, localStorageSet } from '../utils/Helpers';
import { getCustomerAnnouncement } from '../utils/ApiClient';
import { setScreenReaderAlert } from '../utils/Accessibility';

const MS_IN_DAY = 86400000;
const STORAGE_KEY = 'customer_announcements';

function isDismissedWithin30Days(title) {
  const dismissedTime = JSON.parse(localStorageGet(STORAGE_KEY))?.[title];
  return dismissedTime && (Date.now() - dismissedTime) / MS_IN_DAY < 30;
}

export default function Announcement({ id }) {
  const [isClosed, setIsClosed] = useState(false);
  const [announcement, setAnnouncement] = useState(null);
  const markdownRef = useRef(null); // Ref for the ReactMarkdown content

  useEffect(() => {
    const fetchCustomerAnnouncement = async () => {
      let sessionAnnouncement = null;
      try {
        sessionAnnouncement = JSON.parse(sessionStorage.getItem('announcement'));
      } catch {
        sessionAnnouncement = null;
      }

      if (!sessionAnnouncement) {
        const res = await getCustomerAnnouncement();

        if (res.Result !== 0) {
          setAnnouncement(sessionAnnouncement);
          return;
        }

        if (res.CustomerAnnouncement) {
          sessionStorage.setItem('announcement', JSON.stringify(res.CustomerAnnouncement));
          sessionAnnouncement = res.CustomerAnnouncement;
        }
      }

      setAnnouncement(sessionAnnouncement);
    };

    fetchCustomerAnnouncement();
  }, []);

  const title = announcement?.Title;
  const msg = announcement?.[`Description${getLanguage() === 'fr' ? 'French' : ''}`];

  const shouldDisplayBanner = useMemo(
    () => title && msg && !isClosed && !isDismissedWithin30Days(title),
    [title, msg, isClosed]
  );

  useEffect(() => {
    if (shouldDisplayBanner && markdownRef.current) {
      // Use the ref to get the rendered markdown content and trigger the screen reader announcement
      const markdownText = markdownRef.current.innerText || '';
      setScreenReaderAlert(t('ScreenReaderAlert_CustomerAnnouncement_Showing').replace('xCustomerAnnouncementMessage', markdownText));
    }
  }, [shouldDisplayBanner, msg]);

  if (!shouldDisplayBanner) return null;

  return (
    <div id={id} className="banner-cont noPrint">
      <span ref={markdownRef}>
        <ReactMarkdown
          components={{
            strong: 'b',
            // eslint-disable-next-line react/jsx-no-useless-fragment
            p: ({ node, ...props }) => <React.Fragment {...props} />,
            a: ({ node, children, ...props }) => <a {...props} target="_blank">{children}</a>,
          }}
        >
          {msg}
        </ReactMarkdown>
      </span>
      <button
        aria-label={t('CustomerAnnouncement_Close_ButtonDescription')}
        title={t('CustomerAnnouncement_Close_ButtonDescription')}
        className="dismissBtn"
        type="button"
        onClick={() => {
          setScreenReaderAlert(t('ScreenReaderAlert_CustomerAnnouncement_Closed'));
          let dismissedMsgs = JSON.parse(localStorageGet(STORAGE_KEY)) || {};

          // Remove old dismissed messages
          dismissedMsgs = Object.entries(dismissedMsgs).reduce((acc, [key, value]) => {
            if ((Date.now() - value) / MS_IN_DAY <= 30) acc[key] = value;
            return acc;
          }, {});

          dismissedMsgs[title] = Date.now();
          localStorageSet(STORAGE_KEY, JSON.stringify(dismissedMsgs));
          setIsClosed(true);
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
}
